import React, { useRef } from 'react';
import { connect } from 'react-redux';

import { BASE_ZINDEX } from '../popup-factory';
import { closePopup } from '../actions/popup';
import { useContainerScroll } from '../hooks';
import { ScrollContainer } from '../hooks/useContainerScroll';

const DEFAULT_COLOR = '#5ca3b6';

const IntroPopup = (props) => {
  const {
    introduction,
    onClosePopup,
    template_color = DEFAULT_COLOR,
    buyInventory = false,
    index,
    shop_contact,
    isShop,
  } = props;

  const ref = useRef(null);
  const {
    canScrollDown,
    canScrollUp,
    scrollDown,
    scrollUp,
  } = useContainerScroll(ref, [buyInventory, index, shop_contact?.contact_email]);

  return (
    <div className="shop shop-intro-popup" style={{ zIndex: BASE_ZINDEX + index, position: 'absolute' }}>
      <div id="popup-contact">
        <div className="contact">
          <div className="contact-container">
            <div className="row" style={{ maxHeight: '90vh', width: '90vw' }}>
              <div ref={ref}  className="small-12 medium-6 large-8 columns" style={{
                background: 'white',
                maxHeight: '50vh',
                overflow: 'auto',
                minHeight: '240px',
              }}>
              {buyInventory ? 'Order items to add them to the inventory of this shop.' : (introduction && introduction.replace(/<(?:.|\n)*?>/gm, '') !== '' ?
                <div dangerouslySetInnerHTML={{ __html: introduction }} />
              : null)}
              </div>
              <div className="small-12 medium-6 large-4 columns contact-info" style={{ maxHeight: 'none' }}>
                <a className="close-order" onClick={onClosePopup} style={{backgroundColor: template_color }}>
                  {isShop ? 'Start Shopping' : 'Browse Products'}
                </a>
                <p>If you have any questions, please contact:</p>
                <p><strong>{shop_contact.contact_name}</strong></p>
                <p><a style={{color: template_color}} href={`mailto:${shop_contact.contact_email}`}>{shop_contact.contact_email}</a>
                </p>
              </div>
            </div>

            {ref.current ? <>
              <ScrollContainer
                isUp
                onClick={scrollUp}
                canScroll={canScrollUp()}
                color={template_color}
                width={ref.current.clientWidth}
                zIndex={99999}
                top={ref.current.offsetTop - 21}
                left={0}
              />

              <ScrollContainer
                isUp={false}
                onClick={scrollDown}
                canScroll={canScrollDown()}
                color={template_color}
                width={ref.current.clientWidth}
                zIndex={99999}
                top={ref.current.clientHeight + ref.current.offsetTop - 44 - 10}
                left={0}
              />
            </> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => dispatch(closePopup())
});

export default connect(null, mapDispatchToProps)(IntroPopup);
